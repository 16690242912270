// import lazyLoading from '../lazyLoading';

export default {
  name: 'contact',
  caption: 'Contact',
  wdm16_id: '',
  icon: 'icon-cursor',
  path: '/contact',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: [],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};
