/*
* *dev ve asm için ayrı build paramları eklenecek.**/

const IS_LOCAL = process.env.NODE_ENV !== 'production';

// const API_BASE_URL = 'http://10.200.2.2:8890/';

const API_BASE_URL = IS_LOCAL
  ? 'http://localhost:8892/'
  : 'https://wai-api.wisdomera.io/';

const API_POLESTAR_URL = IS_LOCAL
  ? 'http://localhost:8889/'
  : 'http://10.200.2.2:8889/';
//  : 'https://whs-api.wisdomera.io/';
//  : 'http://10.200.2.2:8890/';

const CLIENT_ID = 'wisdomhs';
const CLIENT_SECRET = 'wisdomera';
const GRANT_TYPE = 'password';

const SWIFT_BASE_URL = IS_LOCAL
  ? 'http://localhost:12345/v1/AUTH_test/'
  : 'https://s3.wisdomera.io/v1/AUTH_test/';
  
/*
const API_BASE_URL = IS_LOCAL
  ? 'http://localhost:8888/'
  : 'https://api.wisdomera.io/';

const SWIFT_BASE_URL = IS_LOCAL
  ? 'http://localhost:12345/v1/AUTH_test/'
  : 'https://s3.wisdomera.io/v1/AUTH_test/';

const CLIENT_ID = 'wisdomhs';
const CLIENT_SECRET = 'wisdomera';
const GRANT_TYPE = 'password';
*/

export {
  IS_LOCAL,
  API_BASE_URL,
  SWIFT_BASE_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE,
  API_POLESTAR_URL
};
