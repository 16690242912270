// import lazyLoading from '../lazyLoading';

export default {
  name: 'terminology',
  caption: 'Terminology',
  wdm16_id: '',
  icon: 'icon-book-open',
  path: '/terminology',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: [],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};
